<template>
  <div class="habitation w-full h-full">
    <div class="body w-full h-full">
      <div class="begin flex w-full h-full">
        <div
          class="picture rounded-3xl w-2/5 mr-3 h-full"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1500"
        >
          <div class="images rounded-3xl h-full w-full"></div>
        </div>
        <div
          class="detail-bloc ml-3 w-3/5 overflow-y-scroll"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1500"
        >
          <habitation-page/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HabitationPage from '../../components/helper/habitation/habitation-page'
export default {
  name: 'index',
  components: { HabitationPage }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .begin {
    font-weight: 100;
  }
  .images {
    // height: 40rem;
    // width: 50%;
    background-image: url("../../assets/images/img_habitation.webp");
    background-repeat: no-repeat;
    // background-position: center;
    background-size: cover;
  }
  .detail-bloc {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    .begin {
      flex-wrap: wrap;
    }
    .images {
      height: 23rem;
    }
    .picture, .detail-bloc {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .begin {
      flex-wrap: wrap;
    }
    .images {
      height: 23rem;
    }
    .picture, .detail-bloc {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .begin {
      flex-wrap: wrap;
    }
    .images {
      height: 44rem;
    }
    .picture, .detail-bloc {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
</style>
