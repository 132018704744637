<template>
  <div class="habitation-page">
    <div class="begin mt-6 text-black text-xl">
      <div class="title font-medium text-black text-4xl flex items-baseline">
        <div class="ic w-10">
          <svg-icon
            name="ic_habitation_insurance"
            original
            class="w-8 h-8"
          />
        </div>
        <div class="title-text ml-4">{{ $t('habitationInsurances') }}</div>
      </div><br>
      <div class="sous-title text-2xl">{{ $t('preserveHabitation') }}</div><br>
      <div class="description leading-relaxed" v-html="$t('habitationInsuranceDescription')"></div>
      <div class="what-guarantees text-2xl font-medium mt-8">{{ $t('whatIsGuarantees') }}</div><br>
      <div class="description leading-relaxed">{{ $t('yourMultiRiskContract') }}</div><br>
      <div class="guarantees-description">
        <div
          v-for="(item, index) in guarantees"
          :key="index"
          class="conteneur flex items-baseline mb-4"
        >
          <div class="radio w-1 h-1 border-2 p-2 rounded mr-4"></div>
          <div class="gua-desc leading-relaxed">
            <div class="short-title font-medium mb-4">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="non-necessary text-2xl font-medium mt-4">{{ $t('nonNecessaryGuarantees') }}</div>
      <div class="non-necessary-desc leading-relaxed mt-4" v-html="$t('permitToCoverRisks')"></div>
      <div class="bloc-list mt-4">
        <div
          v-for="(item, index) in necessaries"
          :key="index"
          class="conteneur flex items-baseline mb-4"
        >
          <div class="radio w-1 h-1 border-2 p-2 rounded mr-4"></div>
          <div class="gua-desc leading-relaxed">
            <div class="short-title font-medium mb-4">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="subscribe text-xl font-medium mt-8">{{ $t('whoCanSubscribe') }}</div>
      <div class="non-necessary-desc leading-relaxed mt-4">{{ $t('homeOwners') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'habitation-page',
  data () {
    return {
      guarantees: [
        {
          title: this.$t('fireExplosion')
        },
        {
          title: this.$t('habitationThief')
        },
        {
          title: this.$t('waterDamage')
        },
        {
          title: this.$t('habitationOccupationResponsibilities')
        },
        {
          title: this.$t('familiarLifeResponsibilities')
        }
      ],
      necessaries: [
        {
          name: this.$t('electricalDevicesDamage')
        },
        {
          name: this.$t('glassBreakage')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .sous-title {
    color: $aab_gray;
  }
  .svg-fill {
    fill: transparent;
  }
  .non-necessary {
    color: $aab_yellow;
  }
  .list {
    &:last-child {
      margin-right: 0;
    }
  }
  .icons {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .radio {
    border-color: $aab_radio_color;
    padding: .35rem;
  }
  @media only screen and (max-width: 600px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
</style>
